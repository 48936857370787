proposal-form.form_hidden {
    --form-padding: 0;
    --form-margin: 0;
    --form-contents-display: none;
    --form-contents-opacity: 0;
    --form-height: 0;
}

proposal-form.animating.form_hidden {
    --form-contents-display: flex;
    --form-contents-opacity: 0;
    --form-height: 0;
    --form-margin: 125px 0 125px 0;
}

proposal-form.animating.form_shown {
    --form-contents-opacity: 0;
}

proposal-form.form_shown {
    --form-height: fit-content;
    --form-margin: 40px 0 100px 0;
    --form-contents-display: flex;
    --form-contents-opacity: 1;
}

proposal-form {
    --animation-duration: 500ms;
    background-color: var(--bg-color-minor);
    display: flex;
    max-width: inherit;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    transition: height var(--animation-duration) ease-in-out;
}

proposal-form form {
    background: var(--bg-color-major);
    height: var(--form-height);
    width: fit-content;
    padding: var(--form-padding);
    margin: var(--form-margin);
    transition: height var(--animation-duration) ease-in-out;
}

@media screen and (max-width: 800px) {
    proposal-form form {
        padding-left: 30px;
        padding-right: 40px;
        width: 75vw;
    }
}

@media screen and (max-width: 800px) and (min-width: 500px) {
    proposal-form form {
        width: 75vw;
    }
}






@media screen and (max-width: 500px) {
    proposal-form.form_shown {
        --form-padding: 10px 40px 40px 40px;
    }

    proposal-form.animating.form_shown {
        --form-height: 1150px;
    }

    proposal-form.animating.form_hidden {
        --form-padding: 10px 40px 40px 40px;
    }
}

@media screen and (min-width: 500px) {
    proposal-form.form_shown {
        --form-padding: 40px;
    }

    proposal-form.animating.form_shown {
        --form-height: 1150px;
    }

    proposal-form.animating.form_hidden {
        --form-padding: 40px;
    }
}

proposal-form label.shown.required::before {
    content: "*";
    color: red;
    position: absolute;
    left: -10px;
    top: -2px;
}

proposal-form label.shown {
    display: var(--form-contents-display);
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: var(--text-color-minor);
    opacity: var(--form-contents-opacity);
    transition: opacity var(--animation-duration) ease-out;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width: 500px) {
    proposal-form label.shown {
        min-height: 60px;
        margin: 30px 0px;
        font-size: 17px;
        flex-direction: column;
        align-items: left;
    }
}

@media screen and (max-width: 800px) and (min-width: 500px) {
    proposal-form label.shown {
        flex-direction: column;
        align-items: left;
        margin: 40px 20px;
        font-size: 20px;
    }
}

@media screen and (max-width: 400px) {
    proposal-form label.shown {
        font-size: 15px;
    }
}

@media screen and (min-width: 800px) {
    proposal-form label.shown {
        min-height: 70px;
        margin: 20px 20px;
        font-size: 20px;
    }
}

proposal-form label.hidden {
   display: none;
}

proposal-form label.shown * {
    border: none;
    border-bottom: 1px solid var(--text-color-major);
    color: var(--text-color-major);
    outline: none;
    background: transparent;
    height: fit-content;
}

@media screen and (min-width: 800px) {
    proposal-form label.shown input {
        max-width: 50%;
        margin-left: 20px;
    }
}

@media screen and (min-width: 500px) and (max-width: 800px) {
    proposal-form label.shown input {
        margin-top: 0;
    }
}


@media screen and (max-width: 800px) {
    proposal-form label.shown input {
        margin-top: 10px;
        margin-left: 0;
    }

    proposal-form label.shown select {
        margin-left: 0;
        margin-top: 10px;
        max-width: 80%;
    }
}

proposal-form label.shown textarea {
    background: var(--bg-color-minor);
    border: none;
    height: 70px;
    margin-left: 20px;
}

@media screen and (max-width: 800px) {
    proposal-form label.shown textarea {
        margin-top: 10px;
        margin-left: 0;
    }

}

proposal-form label.shown input[type="file"] {
    border: none;
    width: fit-content;
}

proposal-form label.shown input[type="date"] {
    border: none;
    min-width: fit-content;
    max-width: 80%;
}

@media screen and (min-width: 800px) {
    proposal-form label.shown select {
        max-width: 80%;
        margin-left: 20px;
        outline: none;
    }
}


proposal-form h2 {
    font-family: "Crimson Text", serif;
    transition: opacity var(--animation-duration) ease-out;
    font-size: 51.2px;
    line-height: 61.4px;
    font-weight: 600;
    margin-top: 50px;
    display: var(--form-contents-display);
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 0;
    opacity: var(--form-contents-opacity);
}

@media screen and (max-width: 800px) {
    proposal-form h2 {
        font-size: 40px; 
        line-height: 46px;
    }
}

proposal-form h2::before {
    transition: opacity var(--animation-duration) ease-out;
    content: "";
    display: block;
    width: 9.5rem;
    height: .5rem;
    background: var(--accent-color);
    margin-bottom: .7em;
    display: var(--form-header-display);
    opacity: var(--form-header-opacity);
}

proposal-form input[type="submit"] {
    padding: 20px calc(1.28vw + 5.38px);
    border: 3px solid var(--accent-color);
    color: var(--accent-color);
    border-radius: 6px;
    box-sizing: border-box;
    font-family: sans-serif;
    text-decoration: none;
    font-size: 24px;
    font-weight: 700;
    transition-property: background;
    transition-duration: .7s;
    background: var(--bg-color-major);
}

proposal-form input[type="submit"].form_unfinished {
    color: grey;
    border-color: grey;
}

proposal-form div.submit-area {
    height: fit-content;
    display: var(--form-contents-display);
    opacity: var(--form-contents-opacity);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

proposal-form div.submit-area span.formInfo {
    color: red;
    margin-left: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 17px;
}

proposal-form div.submit-area span.formInfo.submit_done {
    color: green;
}

@media screen and (min-width: 800px) {
    proposal-form input[type="submit"]:hover {
        background: var(--accent-color);
        color: var(--text-color-inverse);
    }

    proposal-form input[type="submit"].form_unfinished:hover {
        background: none;
        color: grey;
    }

    proposal-form div.submit-area {
        margin-right: 20px;
    }

    proposal-form div.submit-area input[type="submit"] {
        order: 2;
    }

    proposal-form div.submit-area span.formInfo {
        order: 1;
    }
}

@media screen and (max-width: 500px) {
    proposal-form div.submit-area {
        justify-content: left;
        width: 100%;
    }

    proposal-form div.submit-area input[type="submit"] {
        order: 1;
    }

    proposal-form div.submit-area span.formInfo {
        order: 2;
        margin-left: 30px;
    }

}



@media screen and (min-width: 500px) and (max-width: 800px) {
    proposal-form div.submit-area {
        justify-content: left;
        margin-left: 20px;
    }

    proposal-form div.submit-area input[type="submit"] {
        order: 1;
    }

    proposal-form div.submit-area span.formInfo {
        order: 2;
        margin-left: 30px;
    }
}
