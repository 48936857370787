

/* body {
  font-family: "DM Sans", sans-serif;
  background-color: #0064a7;
  line-height: 1.5;
  min-height: 300vh;
  position: relative;
} */
.app-container {
/* Smaller Screens */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
/* Larger Screens */
/*3x3 grid*/
/* @media (min-width: 768px) {
  .app-container {
    display: grid;
    grid-template-columns: 200px 1fr 200px; 
    grid-template-rows: auto 1fr auto;
  }
} */
main {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    grid-template-columns: 1fr;
    max-width: 100vw;
}


  /* ****** */
 /* HEADER */
/* ****** */
/* Sticky header */
*,
*:after,
*:before {
  box-sizing: border-box;
}
:root {
  --header-outer-height: 70px;
  --header-inner-height: 60px;
  --header-height-difference: calc(
    var(--header-outer-height) - var(--header-inner-height)
  );
  --header-bg: #fff;
}
.header-outer {
  grid-column: span 3;
  /* Make it stick */
  height: var(--header-outer-height);
  position: sticky;
  top: calc(
    var(--header-height-difference) * -1
  ); /* Multiply by -1 to get a negative value */
  display: flex;
  align-items: center;

  /* Other */
  /* background-color: var(--header-bg); */
  box-shadow: 0 2px 10px 0 rgba(0,0,0, 0.1);
  background-color: #002E5D;
  color: #fff;
}
.header-inner {
  /* Make it stick */
  height: var(--header-inner-height);
  position: sticky;
  top: 0;

  /* Other */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.responsive-wrapper {
  width: 90%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

/* Styling of other header elements */
.header-logo {
  vertical-align: middle;
}
.site-name {
  padding-left: 0px;
  font-family: serif;
  font-size: calc(.821vw + 25.2px);
  font-weight: 500;
}
/* Header Nav */
.header-navigation {
  display: flex;
  flex-wrap: wrap;
}
.header-navigation a,
.header-navigation button {
  vertical-align: middle;
  font-size: 1.125rem;
  color: inherit;
  margin-left: 1.75rem;
  position: relative;
  font-weight: 500;
}
.header-navigation a {
  display: none;
  font-size: 1.125rem;
  color: inherit;
  text-decoration: none;
} 
.header-navigation button {
  border: 0;
  background-color: transparent;
  padding: 0;
}
.header-logo a:hover:after,
.header-navigation a:hover:after,
.header-navigation button:hover:after {
  transform: scalex(1);
}
.header-logo a:after,
.header-navigation a:after,
.header-navigation button:after {
  transition: 0.25s ease;
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: currentcolor;
  transform: scalex(0);
  position: absolute;
  bottom: -2px;
  left: 0;
}
@media (min-width: 1000px) {
  .header-navigation a {
    display: inline-block;
  }
  .header-navigation button {
    display: none;
  }
}


/* NAVBAR */
nav {
  background-color: #0e264b;
  color: #fff;
  }
@media (max-width: 768px) {
 ul {
    display: none;
  }
  .nav-horizontal {
    align-self: center;
  }
}
@media (min-width: 768px) {
  .nav-horizontal {
    display: none;
  }
}
.menu-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 768px) {
  .menu-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }
}
li {
  padding: .5%;
  padding-top: 1%;
  padding-bottom: 1%;
}
@media (min-width: 768px) {
  li {
    padding-top: 10%;
    padding-left: 5%;
  }
}
li:hover {
  background-color: #40424c;
}


/* MAIN */
.summary {
    /* display: flex; */
    padding-left: 40px;
    padding-right: 40px;
    min-height: 170px;
    padding-top: 20px;
    max-width: inherit;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    /* background: var(--bg-color-minor); */
    text-align:justify;
    /* max-width: 80%; */

}


/* ASIDE */
aside {
  padding: 20px;
  background-color: #0e264b;
}


/* FOOTER */
footer {
  grid-column: span 3;
  min-height: 170px;
  padding-top: 20px;
  max-width: inherit;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  /* background: var(--bg-color-minor); */
  background: #292F33;
  text-align: center;
  color: #F0FAFF;
}
.footer-name {
    padding-top: 20px;
    padding-bottom: 20px;
}
.footer-services {
    /* align-self: flex-start; */
    padding: 20px;
    font-family: serif;
    font-size: calc(.821vw + 17.2px);
    font-weight: 100;
    /* max-width: inherit; */
    /* text-align: center;    */
}
.footer-about {
    line-height: 1.1;
    padding: 40px;
}
address {
    display: block;
    font-style: italic;
    font-size: calc(.3125vw + 15px);
    font-family: "Crimson Text", serif;
    font-weight: 400;
    padding-top: 20px;
    padding-bottom: 20px;
  }
footer p {
    max-width: 80%;
}
.disclaimer {
    font-size: 12px;
    line-height: 1.5;
    padding-bottom: 20px;
}
.disclaimer strong {
    font-size: 12px;
    line-height: 1.5;
}
.copyright {
    font-size: 12px;
    line-height: 1;
    padding-top: 20px;
    padding-bottom: 40px;
}

