:root {
    --bg-color-major: white;
    --bg-color-minor: #F4F4F4;
    --header-bg: #EDF4FB;
    --accent-color: #7BA7F2;
    --text-color-major: #213658;
    --text-color-minor: black;
    --text-color-inverse: white;
    --desktop-content-padding: 16vw;
}

